import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'

const Layout = ({ children, title }) => (
   <StaticQuery
      query={graphql`
         query SiteTitleQuery {
            site {
               siteMetadata {
                  title
               }
            }
         }
      `}
      render={data => (
         <>
            <Header
               siteTitle={data.site.siteMetadata.title}
               pageTitle={title}
            />
            <main className="main">{children}</main>
            <Footer siteTitle={data.site.siteMetadata.title} />
         </>
      )}
   />
)

Layout.propTypes = {
   children: PropTypes.node.isRequired,
}

export default Layout
