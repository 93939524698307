import React, { Component } from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import styles from './header.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faExclamationTriangle,
   faArrowRight,
} from '@fortawesome/free-solid-svg-icons'

class Notification extends Component {
   // const [isIE, setIsIE] = useState(false)
   // useEffect(() => {
   //    setIsIE(/*@cc_on!@*/ false || !!document.documentMode)
   // }, [])

   constructor() {
      super()
      this.state = { isIE: null }
   }
   componentDidMount() {
      // Check if user is in IE
      // (only fires in browser/DOM environment, not in server)

      // Manually trigger load event
      var evt = document.createEvent('Event')
      evt.initEvent('load', false, false)
      window.dispatchEvent(evt)

      this.setState({
         isIE:
            typeof window.MSCompatibleInfo != undefined &&
            window.MSCompatibleInfo != null
               ? 'true'
               : 'false',
      })
   }

   render() {
      return (
         <>
            {this.state.isIE === 'true' && (
               <div className={styles.old_browser}>
                  <div className={styles.container}>
                     <div className={styles.notification__icon}>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                     </div>
                     <p>
                        Hi! You're using an <b>unsupported browser</b> (Internet
                        Explorer).
                        <br />
                        We recommend upgrading to the latest{' '}
                        <OutboundLink
                           href="//microsoft.com/en-us/windows/microsoft-edge"
                           target="_blank">
                           Microsoft Edge
                        </OutboundLink>
                        ,{' '}
                        <OutboundLink
                           href="//google.com/chrome"
                           target="_blank">
                           Google Chrome
                        </OutboundLink>
                        , or{' '}
                        <OutboundLink
                           href="//mozilla.org/en-US/firefox/new/"
                           target="_blank">
                           Firefox
                        </OutboundLink>
                        .
                     </p>
                     <p>
                        Questions?{' '}
                        <OutboundLink href="mailto:info@spcfinancial.com">
                           Email us
                        </OutboundLink>{' '}
                        or chat with us by clicking the icon at the bottom
                        right.
                        <span className={styles.arrow__icon}>
                           <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                     </p>
                  </div>
               </div>
            )}
         </>
      )
   }
}

export default Notification
