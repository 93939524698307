import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
   title,
   description,
   lang,
   image,
   pathname,
   article,
   articlePublishedAt,
}) => (
   <StaticQuery
      query={query}
      render={({
         site: {
            siteMetadata: {
               defaultTitle,
               titleTemplate,
               defaultDescription,
               siteUrl,
               defaultImage,
               twitterUsername,
            },
         },
      }) => {
         const seo = {
            title: title || defaultTitle,
            description: description || defaultDescription,
            image: `${siteUrl}${image || defaultImage}`,
            siteUrl: `${siteUrl}${pathname || '/'}`,
         }

         return (
            <>
               <Helmet
                  htmlAttributes={{ lang }}
                  title={seo.title}
                  titleTemplate={titleTemplate}>
                  <meta name="description" content={seo.description} />
                  <meta name="image" content={seo.image} />
                  {seo.siteUrl && (
                     <meta property="og:url" content={seo.siteUrl} />
                  )}
                  {(article ? true : null) && (
                     <meta property="og:type" content="article" />
                  )}
                  {articlePublishedAt && (
                     <meta
                        property="article:published_time"
                        content={articlePublishedAt}
                     />
                  )}
                  {seo.title && (
                     <meta property="og:title" content={seo.title} />
                  )}
                  {seo.description && (
                     <meta
                        property="og:description"
                        content={seo.description}
                     />
                  )}
                  {seo.image && (
                     <meta property="og:image" content={seo.image} />
                  )}
                  <meta name="twitter:card" content="summary_large_image" />
                  {twitterUsername && (
                     <meta name="twitter:creator" content={twitterUsername} />
                  )}
                  {seo.title && (
                     <meta name="twitter:title" content={seo.title} />
                  )}
                  {seo.description && (
                     <meta
                        name="twitter:description"
                        content={seo.description}
                     />
                  )}
                  {seo.image && (
                     <meta name="twitter:image" content={seo.image} />
                  )}
               </Helmet>
            </>
         )
      }}
   />
)

export default SEO

SEO.propTypes = {
   title: PropTypes.string,
   description: PropTypes.string,
   lang: PropTypes.string,
   image: PropTypes.string,
   pathname: PropTypes.string,
   article: PropTypes.bool,
   articlePublishedAt: PropTypes.string,
}

SEO.defaultProps = {
   title: null,
   description: null,
   lang: `en`,
   image: null,
   pathname: null,
   article: false,
   articlePublishedAt: null,
}

const query = graphql`
   query SEO {
      site {
         siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: siteUrl
            defaultImage: image
            twitterUsername
         }
      }
   }
`
